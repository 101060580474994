$primary-nav-width: 16%;

#primary-nav .level-1 {
	padding: 8px 3px;
    width: $primary-nav-width;

    @include breakpoint($sm){
    	padding: 8px 1px;
    	width: 15%;
    	&:nth-child(2) {
            width: 19%;
        }
    	&:nth-child(3) {
            width: 21%;
        }
    }

    @include breakpoint($md){
    	padding: 8px 3px;
    	width: 15.5%;

        &:nth-child(3) {
            width: 19%;
        }
    }
}

#drawer-nav {
	.level-4, .level-5 {
		display: none;
	}
}

.title-bar {
    font-family: $wcm-regular;
    padding-top: 5px;
    @include breakpoint($xs) {
        font-size: 22px;
        // line-height: 2;
    }
}

.brand{

	.brand__lockup{

		.appt_header {
			display: flex;
			flex-wrap: wrap;
			padding-top:25px;
			margin-left: auto;
			margin-right: auto;
			justify-content: center;
			align-items: center;

			@include breakpoint($md) {
				float:right;

				.btn--wcm{
					&:first-of-type{
						margin-right: 1rem;
					}
				}
			}

			.btn--wcm{
				border: 4px solid $wcm-bright-orange;
				border-radius: 20px;

				&:hover{
					border: 4px solid $wcm-red;
				}
			}
		}

		@media only screen and (max-width: 768px){
			.appt_header {
				margin-top:0px;
			}
			.appt_header a{
				margin-bottom:5px;
			}
		}
	}
}


.brand__logo{
	display: inline-block;
}

.block-menu-block {
    @include breakpoint($md) {
        -moz-column-count: 3;
        -webkit-column-count: 3;
        -ms-column-count: 3;
        -o-column-count: 3;
        column-count: 3;
        -webkit-column-gap: 15px;
        -moz-column-gap: 15px;
        -ms-column-gap: 15px;
        -o-column-gap: 15px;
        column-gap: 15px;
        margin-bottom: 1em;
    }
}

#block-menu-block-2 {
    max-width: 1140px;
    // padding-left: 20px;
    // padding-right: 20px;
    padding: 0 1em;

    margin: 25px 20px 25px;
    font-size: 12px;
    line-height: 28px;
    position: relative;
    border: 1px solid $wcm-border-gray;
    border-radius: 10px;
    color: $wcm-med-gray;

    h2 {
        font-size: 14px;
        margin: 14px 0 10px;
        text-align: center;
        color: $wcm-dark-orange;
        cursor: pointer;

        &:after {
            content: "\e80a";
            @include fontello();
            width: 45px;
            display: block;
            position: absolute;
            top: 8px;
            right: 0;
            font-size: 20px;
        }

        &.menu-active:after {
            content: "\e809";
        }
    }

    .content {
        display: none;
        margin: 0;
        text-align: center;

        ul {
            padding: 0;
            margin: 0 0 5px 0;
        }

        a {
            color: $wcm-med-gray;
        }
    }

    @include breakpoint($sm) {
        display: none;
    }
}

/* =Default Select Elements
----------------------------------------------------------*/

.form-select {
	max-width: none;
	height: 50px;
	border: 1px solid $wcm-border-gray;
	box-shadow: none;
	appearance: none;
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M207 381.5L12.7 187.1c-9.4-9.4-9.4-24.6 0-33.9l22.7-22.7c9.4-9.4 24.5-9.4 33.9 0l154.7 154 154.7-154c9.4-9.3 24.5-9.3 33.9 0l22.7 22.7c9.4 9.4 9.4 24.6 0 33.9L241 381.5c-9.4 9.3-24.6 9.3-34 0z' fill='%23cf4520'/%3E%3C/svg%3E");
	  background-repeat: no-repeat;
	  background-position: right 15px top 50%;
	background-size: 20px 20px;
	padding-right: 50px;
  }

/***** HOMEPAGE *****/
.front {

	#division_categories {
		@include clearfix();
		clear: both;
		margin-top: 10px;
	}
	#division_feature {
		// display: none;
		// overflow: hidden;

		position: relative;

		@include clearfix();

		.slides {
			// width: 5000px;
			position: relative;
			display: block;
			overflow: hidden;
		}

		.slide {
			// max-width: 1140px;
			width: 100%;
			background: $wcm-dark-orange;
			color: $wcm-white;
			border-top: 4px solid $wcm-dark-orange;
			font-size: 14px;

			position: relative;
			top: 0;
			left: 0;
			margin-bottom: 10px;
			@include breakpoint($md) {
				z-index: -1;
			}
			// display: block;

			@include transition(0.5s opacity);

			opacity: 1;
			@include breakpoint($md) {
				float: left;
				opacity: 0;
				position: absolute;
				margin-bottom: 0px;
			}

			&.active {
				opacity: 1;
				position: static;
			}

			h2 {
				color: $wcm-white;
				margin-top: 15px;
			}
			a {
				margin-left: 0;
				// color: $wcm-white;
				border-color: $wcm-bright-orange;
				background: $wcm-white;
				// color: $wcm-yellow;
				&:hover {
					border-color: $wcm-bright-orange;
					background: $wcm-bright-orange;
					// color: initial;
				}

				&:focus, &:active {
					color: $wcm-dark-orange;
				}
			}

			ul {
				// list-style: none;
				// padding: 0;
				padding-left: 15px;
			}

			.cardiac_content {
				@include breakpoint($md) {
					float: left;
					width: 35%;
					padding: 0 15px;
				}
				padding: 0 15px 10px;
			}

			.thoracic_content {
				@include breakpoint($md) {
					float: right;
					width: 35%;
					padding: 0 15px;
				}
				padding: 0 15px 10px;
			}

			img {
				width: 100%;
				@include breakpoint($md) {
					width: 65%;
					float: left;
				}
			}
		}
	}

	.slide_buttons_container {
		// margin-top: 20px;

		display: none;
		@include breakpoint($md) {
			display: block;
		}

		.slide_buttons {
			&:first-child {
				padding-left: 0;
				padding-right: 0;
				@include breakpoint($md) {
					padding-right: 7px;
				}
			}

			&:last-child {
				padding-right: 0;
				padding-left: 0px;
				@include breakpoint($md) {
					padding-left: 7px;
				}
			}
		}
	}

	.callout_btns_home {
		@include breakpoint($md) {
			padding-right: 0;
			text-align: right;
			margin-top: 0;
		}
		margin-top: 20px;

		a {
			display: inline-block;
			padding: 10px 20px;
			width: 49%;
			@include breakpoint( (max:480px) ){
				width: 100%;
			}
			// width: 200px;
			height: 50px;
			margin-bottom: 5px;

			&:before {
				line-height: 2.5;
			}

			/*&.btn_refer_patient {
				line-height: 1.2;
				padding-top: 5px;
			}*/
		}
	}

	#cardiac_button, #thoracic_button {
		display: block;
		background-color: $wcm-bright-orange;
		text-align: center;
		// width: 50%;
		text-decoration: none;
		position: relative;

		/*.shadow {
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			border: 4px solid $wcm-dark-orange;
			display: none;
		}*/

		&:after {
			content: '';
		    display: none;
		    position: absolute;
		    top: 0;
		    width: 100%;
		    height: 100%;
		    margin-right: -4px;
		    border: 4px solid $wcm-dark-orange;
		    // box-shadow: inset 0px 0px 0px 4px $wcm-dark-orange;
		}

		&:hover, &.active {
			background-color: $wcm-dark-orange;
			&:after {
				display: block;
			}
		}

		h1 {
			font-size: 18px;
			margin: 0;
			padding: 15px 0 5px 0;
			color: $wcm-white;
		}
		h2 {
			font-size: 14px;
			margin: 0px 0 15px 0;
			color: $wcm-white;
			font-family: $wcm-regular;
		}

		img {
			width: 100%;
		}
	}

	.why_choose_weill {
		margin: 40px 0 20px 0;
		h3 {
			border-bottom: 4px solid $wcm-border-gray;
			font-size: 18px;
		}
	}

	.radix-layouts-header {
		.pane-bundle-text {
			text-align: center;
			padding: 15px 10px;
			@include breakpoint($sm) {
				padding: 15px 50px;
			}
			@include breakpoint($md) {
				padding: 15px 100px;
			}
		}
	}

	.pane-video-archive-panel-pane-2  {
		.pane-title {
			border-bottom: 1px solid $wcm-border-gray;
			margin-bottom: 20px;
		}
	}

	.col-md-7 {
		@include breakpoint($sm) {
			padding-right: 30px;
		}
	}
	.col-md-5 {
		@include breakpoint($sm) {
			padding-left: 30px;
		}
	}

	.col-sm-7 {
		@include breakpoint($sm) {
			padding-right: 30px;
		}
	}
	.col-sm-5 {
		@include breakpoint($sm) {
			padding-left: 30px;
		}
	}

	.pane-news-panel-pane-7  {
		margin-bottom: 30px;
		.pane-title {
			border-bottom: 1px solid $wcm-border-gray;
			// margin-bottom: 20px;
		}

		.view-teaser {
			// border-bottom: none;
			padding-bottom: inherit;
		}

		.teaser-title {
			margin-bottom: 0;

			a {
                &:after {
                    content: '\e80d';
                    color: $wcm-bright-orange;
                    font-size: 80%;
                    padding-left: 5px;
                    @include fontello();
                }
                &.external-link:after {
                	content: '\e811';
                }
            }
		}

		.view-footer {
			clear: both;
			border-top: 1px solid $wcm-border-gray;
			margin-top: 10px;
			padding-top: 10px;

			/*a {
				&:after {
					content: '\e80d';
					padding-left: 10px;
					@include fontello();
				}
			}*/
		}
	}

	.accolades-slideshow {
		@include clearfix();
		margin-bottom: 20px;

		img {
			width: 100%;
		}
	}

	.cycle-slideshow {
		background: none;
	}

	.cycle-prev {
		position: absolute;
		left: -5px;
		top: 25%;
		z-index: 110;
	}
	.cycle-next {
		position: absolute;
		right: 5px;
		top: 25%;
		z-index: 110;
	}
	.cycle-btn-prev {
		cursor: pointer;
		position: relative;
	    height: 20px;
	    // @include transition(all 0.25s);
	    background-color: $wcm-border-gray;
	    display: inline-block;
	    zoom: 1;
	    margin-left: 10px;
	    padding: 10px 5px 0px 0px;
	    color: $wcm-border-gray;
	    @include rotate(180deg);

	    &:hover {
	    	opacity: 1;
	    	color: $wcm-bright-orange;
	    	background-color: $wcm-bright-orange;

	    	&:after {
	    		border-left: 5px solid $wcm-bright-orange;
	    	}
	    }

	    &:after {
	        content: "";
	        position: absolute;
	        width: 0;
	        height: 0;
	        border-top: 10px solid transparent;
	        border-left: 5px solid $wcm-border-gray;
	        border-bottom: 10px solid transparent;
	        margin: -10px 0px 0 5px;
	    }
	    &:before {
	        content: "";
	        position: absolute;
	        width: 0;
	        height: 0;
	        left: 0;
	        border-top: 10px solid transparent;
	        border-left: 5px solid $wcm-white;
	        border-bottom: 10px solid transparent;
	        margin: -10px 0px 0 0px;
	    }
	}

	.cycle-btn-next {
		cursor: pointer;
		position: relative;
	    height: 20px;
	    // @include transition(all 0.25s);
	    background-color: $wcm-border-gray;
	    display: inline-block;
	    zoom: 1;
	    margin-left: 10px;
	    padding: 10px 5px 0px 0px;
	    color: $wcm-border-gray;

	    &:hover {
	    	opacity: 1;
	    	color: $wcm-bright-orange;
	    	background-color: $wcm-bright-orange;

	    	&:after {
	    		border-left: 5px solid $wcm-bright-orange;
	    	}
	    }

	    &:after {
	        content: "";
	        position: absolute;
	        width: 0;
	        height: 0;
	        border-top: 10px solid transparent;
	        border-left: 5px solid $wcm-border-gray;
	        border-bottom: 10px solid transparent;
	        margin: -10px 0px 0 5px;
	    }
	    &:before {
	        content: "";
	        position: absolute;
	        width: 0;
	        height: 0;
	        left: 0;
	        border-top: 10px solid transparent;
	        border-left: 5px solid $wcm-white;
	        border-bottom: 10px solid transparent;
	        margin: -10px 0px 0 0px;
	    }
	}
}
/******************/

#map_canvas {
	height: 350px;
	margin-bottom: 15px;
    width: 100%;
}
#map_custom_directions {

	@include breakpoint($sm) {
		float: left;
		width: 35%;
		margin-right: 20px;
	}

	border-top: 1px solid $wcm-border-gray;
	border-bottom: 1px solid $wcm-border-gray;
	margin-bottom: 20px;

	#custom_form {
	}

	#directions {
		display: none;
	}

	.travel-selection .select2-container {
		width: 120px !important;
	}
}

// .page-node-17 {
.page-node-82 {
	.main-content {
		@include clearfix();

		.pane-node-body {
			@include breakpoint($sm) {
				width: 62%;
				float: left;
			}
		}
	}
}

.view-news {
	.view-teaser {
        border-bottom: 1px solid $wcm-border-gray;
        // padding-top: 15px;
        // display: table;
        // width: 100%;
        @include breakpoint($sm) {
            padding-bottom: 8px;
        }

        &:last-child {
            border-bottom: none;
        }
    }
}

/*******************/
.information-sidebar {
	.pane-profiles-panel-pane-4, .pane-profiles-panel-pane-5, .pane-profiles-panel-pane-6 {
		margin-bottom: 30px;
		font-size: 13px;
		h3.pane-title {
			margin-top: 0;
			font-size: 18px;
			color: $wcm-dark-gray;
		}

		.views-row {
			@include clearfix();
			border-top: 1px solid $wcm-border-gray;
			padding-top: 5px;
			padding-bottom: 5px;

			.views-field-field-profile-image, .views-field-field-pops-profile-link-1 {
				float: left;
				margin-right: 15px;
				width: 80px;
			}

			h4 {
				margin-top: 0;
				font-size: 16px;
			}
		}
	}

	.pane-bundle-headshot {
		margin-bottom: 30px;
		font-size: 13px;
		h3.pane-title {
			margin-top: 0;
			font-size: 18px;
			color: $wcm-dark-gray;
		}

		.headshot-entry {
			@include clearfix();
			border-top: 1px solid $wcm-border-gray;
			padding-top: 5px;
			padding-bottom: 5px;
			width: 100%;

			.headshot-details {
				width: auto;
			}

			img {
				float: left;
				margin-right: 15px;
				width: 80px;
				margin-bottom: 0;
			}

			.name {
				font-family: $wcm-bold;
				font-size: 13px;
				font-weight: 400;
				color: $wcm-dark-gray;
			}

			.title {
				font-size: inherit;
				padding: inherit;
				word-wrap: break-word;

				a {
					font-size: inherit;
					font-family: inherit;
				}
			}

			.description {
				a {
					font-size: inherit;
					font-family: inherit;
				}
			}

			a {
				font-family: $wcm-bold;
				font-size: 13px;
				color: $wcm-red;

				&:after {
					font-size: 60%;
					vertical-align: top;
				}
			}
		}
	}
}

.sidebar-btn {
	width: auto;
	// height: auto;
	// white-space: inherit;
	display: inline-block;
	/*&:before {
		content: '';
	}*/
	&:hover {
		&:before {
			left: 96%;
		}
	}
}

.field-name-field-news-category {
    h3 {
    	margin-top: 0;
    }
}

.page-node-98 {
	.fancybox-video {
		border: none;

		display: inline-block;
		&:after {
			content: '\e80d' !important;
		}
	}
}

.pane-video-archive-panel-pane-3, .pane-video-archive-panel-pane-4  {
	.pane-title {
		border-bottom: 1px solid $wcm-border-gray;
		margin-bottom: 20px;
	}
	margin-bottom: 20px;
}

.pane-news-panel-pane-8, .pane-news-panel-pane-9  {
	.view-teaser {
		margin-bottom: 20px;
	}

	.pane-title {
		border-bottom: 1px solid $wcm-border-gray;
		margin-bottom: 20px;
	}
	margin-bottom: 20px;
}

.pane-profiles-panel-pane-3 {
	.grid-title {
		border-top: 1px solid $wcm-border-gray;
		// border-bottom: 1px solid $wcm-border-gray;
		margin-top: 0;
		margin-bottom: 20px;
		padding-top: 30px;

		&:first-child {
			border-top: none;
			margin-top: 20px;
		}
	}
	.views-view-grid {
		.grid-item {
			padding-bottom: 30px;

			.views-field-field-pops-profile-link-1 {
				margin-bottom: 10px;
			}
		}
	}
}

.pane-profiles-panel-pane-7, .pane-profiles-panel-pane-8 {
	.views-view-grid {
		.grid-item {
			padding-bottom: 30px;

			.views-field-field-pops-profile-link-1 {
				margin-bottom: 10px;
			}
		}
	}
}

.view-video-archive {

	.view-content {
		// @include clearfix();
		.views-row {
			@include clearfix();
			margin-bottom: 10px;
		}
	}

	.video {
		float: left;
		margin-right: 10px;
		position: relative
	}
	.playhead {
		width: 20px;
		height: 20px;
		border-radius: 50%;
		margin: 0;
		left: 5px;
		top: 5px;

		&:before {
			position: relative;
			left: 1px;
			font-size: 12px;
			color: $wcm-white;
			line-height: 0.4em;
		}
	}

	.fancybox-video {
		border: none;

		img {
			width: 95px;
		}

		&.external-link {
			// display: inline-block;
			// display: none;
			&:after {
				content: '';
				// content: '\e80d';
			}
		}
	}

	.view-footer {
		clear: both;
		border-top: 1px solid $wcm-border-gray;
		margin-top: 10px;
		padding-top: 10px;
	}
}

.pane-video-archive-panel-pane-1 {
	.views-row:after, .views-row:before {
		display: none !important;
	}
}

.pane-bundle-quick-links {
	clear: both;
}

.conditions-treatments-list .fieldable-panels-pane {
	@include breakpoint($sm) {
        -moz-column-count: 2;
        -webkit-column-count: 2;
        -ms-column-count: 2;
        -o-column-count: 2;
        column-count: 2;
        -webkit-column-gap: 15px;
        -moz-column-gap: 15px;
        -ms-column-gap: 15px;
        -o-column-gap: 15px;
        column-gap: 15px;
        margin-bottom: 1em;
    }
}

.appt_header {
	float:right;
	display: flex;
	flex-wrap: wrap;
    // width: 450px;
    padding-top:25px;
}


.appt_header a {
	margin-bottom: 5px;
	margin-left:5px;

	&:hover, &:focus {
		border: 4px solid $wcm-red;
	}
}

@include breakpoint($xs) {
	.appt_header_mobile{text-align:center; display:flex;}
}

@include breakpoint($md) {
	.appt_header_mobile{display:none;}
}

@include breakpoint($sm){
	.appt_header_mobile{display:none;}
}

@media only screen and (max-width: 768px){
	.appt_header_mobile{display:none;}
	.appt_header {
		display:block;
		margin-top:0px; }
	.appt_header a{margin-bottom:5px;}
}

@media only screen and (max-width: 736px){
	.appt_header_mobile{display:flex;}
}
.ondemand {
	width: 100%
}

a.btn.btn--wcm.ondemand.external-link:hover{
	padding: 10px;
}

/* Button for WCM on demand second opinion */
.btn--wcm1{
position: relative;
display: block;
width: 260px;
margin: 0 auto;
-webkit-border-radius: 20px;
border-radius: 20px;
border-width: 4px;
font-size: 13px;
color: #cf4520;
background-color: transparent;
border-color: #e7751d;
}

/* New home page button */
.front .callout_btns_home1 {
	margin-top: 20px;
}
@media screen and (min-width: 992px) {
.front .callout_btns_home1 {
		padding-right: 0;
		text-align: right;
		margin-top: 0;
	}
}
.front .callout_btns_home1 a {
	display: inline-block;
	padding: 10px 20px;
	width: 99%;
	height: 50px;
	margin-bottom: 5px;
}
@media screen and (max-width: 480px) {
	.front .callout_btns_home1 a {
		width: 100%;
	}
}

.front .callout_btns_home1 a:before {
	line-height: 2.5;
}

.btn--wcm1:hover, .btn--wcm1:focus, .btn--wcm1.active, .open > .btn--wcm1.dropdown-toggle {
	color: #fff;
	background-color: #cf4520;
	border-color: #b31b1b;
	border-width: 4px;
}

.btn--wcm1:before {
	position: absolute;
	height: 100%;
	font-size: 125%;
	color: #fff;
	transition: all 0.3s;
	content: '\e802';
	font-family: "fontello";
	font-style: normal;
	font-weight: normal;
	speak: none;
	display: inline-block;
	text-decoration: inherit;
	text-align: center;
	font-variant: normal;
	text-transform: none;
	line-height: 1em;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	line-height: 2.0;
}

.btn--wcm1:before {
	left: 70%;
	opacity: 0;
	top: 0;
}

.btn--wcm1:hover:before {
	left: 86%;
	opacity: 1;
}

.btn--wcm1.external-link:before {
	content: '\e811';
	margin-left:15px;
}

.btn--wcm1.external-link:after {
	display: none;
}
/* End button for WCM on demand second opinion */
